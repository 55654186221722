<template>
  <div>
    <FormError :errors="errors.save" />
    <div class="flex justify-between">
      <Input
        :label="$t('global_firstname')"
        :editable="editable"
        v-model="account.firstName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start relative"
        :error="errors.firstName"
      />
      <Input
        :label="$t('global_lastname')"
        :editable="editable"
        v-model="account.lastName"
        classAdd="w-full ml-1 flex flex-col justify-start items-start relative"
        :error="errors.lastName"
      />
    </div>
    <Input
      :label="$t('global_email')"
      :editable="false"
      v-model="account.emailAddress"
      classAdd="mt-5 flex flex-col justify-start items-start relative"
    />
    <div class="mt-5 flex flex-col justify-start items-start">
      <label class="text-sm font-proximaMedium" for="phoneNumber"
        >{{ $t("global_phone_number") }} *</label
      >
      <input
        v-if="!editable"
        type="text"
        :readonly="!editable"
        v-model="account.phoneNo"
        class="w-full rounded-sm px-3 py-3 my-1 text-sm"
      />
      <vue-tel-input
        v-if="editable"
        id="phoneNumber"
        required
        v-model="phone"
        validCharactersOnly
        @validate="telValidate"
        class="w-full"
        :readonly="!editable"
      />
      <FormError :errors="errors.phoneNo" />
    </div>
    <Input
      :label="$t('global_street_address')"
      :editable="editable"
      v-model="account.kennelAddress"
      class="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.kennelAddress"
    />
    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="country">{{
          $t("global_country")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.country"
          @change="inputSelectCountry()"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.country" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="state">{{
          $t("global_state")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.state"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in statesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.state" />
      </div>
    </div>
    <div class="mt-5 flex justify-between">
      <Input
        :label="$t('global_city')"
        :editable="editable"
        v-model="account.city"
        classAdd="w-full mr-1 flex flex-col justify-start items-start relative"
        :error="errors.city"
      />
      <Input
        :label="$t('global_postal_code')"
        :editable="editable"
        v-model="account.postalCode"
        classAdd="w-full ml-1 flex flex-col justify-start items-start relative"
        :error="errors.postalCode"
      />
    </div>

    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="kennelClub">{{
          $t("after_verification_kannel")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.kennelClub"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option
            v-for="item in sortedKennelClubs"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.kennelClub" />
      </div>

      <!--  <Input
        :label="$t('after_verification_kannel')"
        :editable="editable"
        v-model="account.kennelClub"
        classAdd="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
        :error="errors.kennelClub"
      />
 -->

      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="kennelFoundationDate">{{
          $t("global_registration_date") + "*"
        }}</label>
        <input
          type="date"
          id="kennelFoundationDate"
          v-model="account.kennelFoundationDate"
          class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
        />
        <FormError :errors="errors.kennelFoundationDate" />
      </div>
    </div>

    <Input
      :label="$t('after_verification_kannel_registration_name')"
      :editable="editable"
      v-model="account.kennelName"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.kennelName"
    />
    <Input
      :label="$t('after_verification_kannel_registration_number')"
      :editable="editable"
      v-model="account.kennelRegistrationNo"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.kennelRegistrationNo"
    />
  </div>
</template>

<script>
import Input from "../forms/Input_.vue";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
import FormError from "../warning/FormError.vue";
import moment from "moment";

import { updateDogBreeder } from "../../services/dogBreedersService";
import { getKennelClubs } from "../../services/dogBreedersService";
import { getCountries } from "../../services/countriesServices";
import { getStates } from "../../services/stateServices";

export default {
  props: { editable: Boolean, data: {} },
  components: {
    Input,
    VueTelInput,
    FormError,
  },
  data() {
    return {
      account: {
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        phoneNo: this.data.phoneNo,
        postalCode: this.data.postalCode,
        kennelAddress: this.data.kennelAddress,
        kennelClub: this.data.kennelClub,
        kennelName: this.data.kennelName,
        kennelRegistrationNo: this.data.kennelRegistrationNo.toString(),
        kennelFoundationDate: moment(
          String(this.data.kennelFoundationDate)
        ).format("YYYY-MM-DD") /* + "-01-01" */,
        country: this.data.country,
        state: this.data.state,
        city: this.data.city,
        emailAddress: this.data.emailAddress.toLowerCase(),
      },
      phone: this.data.phoneNo,

      errors: {},
      countriesList: [],
      statesList: [],
      kennelClubsList: [],
      options: {
        placeholder: this.$t("global_phone_number_placeholder"),
      },
    };
  },
  async created() {
    const a = await getCountries();
    this.countriesList = a.data;

    if (this.account.country) {
      const c = await getStates(this.account.country);
      this.statesList = c.data;
    }

    const b = await getKennelClubs();
    this.kennelClubsList = b.data;
  },
  computed: {
    sortedKennelClubs: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.kennelClubsList.sort(compare);
    },
  },
  methods: {
    telValidate(telnumber) {
      this.account.phoneNo = telnumber.number;
      /*  if (telnumber.valid) {
      } else {
        this.errors.phoneNo = "phone number is not valid";
      } */
    },
    async inputSelectCountry() {
      const a = await getStates(this.account.country);
      this.statesList = a.data;
    },

    validate() {
      const errors = {};

      if (this.phoneNo === "") errors.phoneNo = "Phone number is required";

      if (this.account.firstName.trim() === "")
        errors.firstName = "FirstName  is required";
      if (this.account.lastName.trim() === "")
        errors.lastName = "LastName  is required";
      if (this.account.postalCode.trim() === "")
        errors.postalCode = "postal Code  is required";
      if (this.account.kennelAddress.trim() === "")
        errors.kennelAddress = "Address is required";
      if (this.account.city.trim() === "") errors.city = "City  is required";
      if (this.account.kennelClub === "")
        errors.kennelClub = "Kennel Club is required";
      if (this.account.kennelName.trim() === "")
        errors.kennelName = "Kennel Registration is required";
      if (this.account.kennelRegistrationNo.trim() === "")
        errors.kennelRegistrationNo = this.$t(
          "after_verification_error_kannel_registration_number"
        );

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitForm(image) {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      /* var kennelFoundationYearOnly = new Date(
        this.account.kennelFoundationYear
      ).getFullYear(); */

      try {
        await updateDogBreeder(image, this.account);
      } catch (error) {
        console.log(error);
        this.errors.save = this.$t("account_error_save_button");
      }
    },
  },
};
</script>

<style>
.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
</style>
